import React from "react"
import { Link as LinkGatsby } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Heading, Box, Text, Link } from "@chakra-ui/react"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found | THE PROJECT STATUS" urlPage="/404" />
    <Container maxW="800px" minH="640px" px={4}>
      <Box as="h1" textStyle="h1">
        NOT FOUND
      </Box>
      <Text>
        You just hit a route that does not exist. Please go back the{" "}
        <Link as={LinkGatsby} to="/">
          Start
        </Link>
        . Thank you! I hope that you will find what your are looking for.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
